'use strict';

const cache = {
    $qfErrorModal: $('#qf-modal-error')
};


module.exports = {
    initEvents: function () {
        // Stops spinner when modal closes
        cache.$qfErrorModal.on('show.bs.modal', function () {
            $('.js-refresh-btn').on('click', function () {
                location.reload();
            });
            $('.js-back-btn').on('click', function () {
                $('#o2check').val('');
                $('#o2UnitsOptions').selectize()[0].selectize.destroy();
                $('#o2-unit-select-container').hide('slow');
                $('#o2-unit-input-container').show();
                $('#centurylink-existing-yes').prop('checked', false);
                $('#centurylink-existing-no').prop('checked', false);
                $('#adresscheck-submit').attr('disabled', true);
            });
            $.spinner().stop();
        });

        // Set timeout across the board
        $.ajaxSetup({
            timeout: cache.$qfErrorModal.data('failed-to-load-timeout')
        });
    }
};
