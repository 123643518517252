'use strict';

const NotificationMessage = require('telco/components/toastNotification');

const cache = {
    $headerSupportMpListItem: $('.support__list-item .support__link'),
    $hamburgerMenuClose: $('#header .hamburger-menu__list .icon__x-close'),
    $toaster: $('.mp-account-toaster'),
    $headerMessageCenterLink: $('.support__list-item .my-account__link'),
    $notificationHeader: $('.notification-header'),
    $notificationIcon: $('#header .notification-img'),
    $notificationCloseIcon: $('#header .cross-img'),
    $notificationListContainer: $('.mp-maincontent .js-notification-list-container'),
    $notificationContainer: $('.mp-maincontent .js-notification-container'),
    $notificationContainerBack: $('.mp-maincontent .js-notification-container-back')
};

/**
 * Set message as read
 * @param {Object} $this - message element
 */
function setMessageAsRead($this) {
    let form = {
        mid: $this.data('mid'),
        src: $this.data('src')
    };

    $.ajax({
        url: $this.attr('data-url'),
        method: 'POST',
        data: form,
        dataType: 'json',
        success: (data) => {
            cache.$notificationListContainer.empty();
            $('.js-notification-count').attr('data-count', data.notificationCount).text(data.notificationCount);
        }
    });
}


/**
 * Update control and filter messages on click
 * @param {Object} $notificationFilterControl - the jQuery filter control
 */
function handleFilterClick($notificationFilterControl) {
    var type = $notificationFilterControl.val();
    var active = $notificationFilterControl.hasClass('active');
    $notificationFilterControl.toggleClass('active');
    var uri = cache.$notificationListContainer.attr('data-url');
    var url = new URL(uri, window.location.origin);
    url.searchParams.delete('type');
    if (active) {
        $notificationFilterControl.addClass('qf-btn-secondary');
        $notificationFilterControl.removeClass('qf-btn-primary');
        cache.$notificationListContainer.attr('data-url', url.pathname + url.search);
        $('.js-message-item').removeClass('d-none');
    } else {
        $notificationFilterControl.addClass('qf-btn-primary');
        $notificationFilterControl.removeClass('qf-btn-secondary');
        $('.mp-maincontent .js-message-filter').not('[value=' + type + ']').removeClass('active qf-btn-primary').addClass('qf-btn-secondary');
        url.searchParams.append('type', type);
        cache.$notificationListContainer.attr('data-url', url.pathname + url.search);
        $('.js-message-item[data-type=' + type + ']').removeClass('d-none');
        $('.js-message-item:not([data-type=' + type + '])').addClass('d-none');
    }
}

/**
 * load the list messages
 * @param {boolean} fadeIn - fade in or not
 */
function loadMessageList(fadeIn) {
    if (cache.$notificationListContainer.children().length > 0) {
        if (fadeIn) {
            cache.$notificationListContainer.fadeIn('fast', 'linear');
        } else {
            cache.$notificationListContainer.show();
        }
        cache.$notificationContainer.hide();
    } else {
        $.ajax({
            url: cache.$notificationListContainer.attr('data-url'),
            method: 'GET',
            success: (pageContent) => {
                cache.$notificationListContainer.html(pageContent);
                let unreadCount = $(pageContent).find('.js-unread').length;
                $('.js-notification-count').attr('data-count', unreadCount)
                    .text(unreadCount);
                if (fadeIn) {
                    cache.$notificationListContainer.fadeIn('fast', 'linear');
                } else {
                    cache.$notificationListContainer.show();
                }
                cache.$notificationContainer.hide();

                $('.mp-maincontent .js-message-filter').on('click', function () {
                    handleFilterClick($(this));
                    $('body').trigger('gtm:mp:messageFilterClick', { btnName: $(this).val() });
                });

                $('.js-message-item').on('click', function () {
                    if ($(this).data('src') === 'COMM') {
                        cache.$notificationListContainer.hide();
                        if ($(this).hasClass('js-unread')) {
                            setMessageAsRead($(this));
                        }

                        cache.$notificationContainer.find('.message-date').html($(this).find('.message-date').html());
                        cache.$notificationContainer.find('.message-subject').html($(this).find('.message-subject').html());
                        cache.$notificationContainer.find('.message-body').html($(this).find('.message-body').val());

                        cache.$notificationContainer.show();
                    } else if ($(this).data('src') === 'IER') {
                        let form = {
                            mid: $(this).data('mid'),
                            data: $(this).find('.message-data').val()
                        };

                        $.ajax({
                            url: $(this).find('.message-data').attr('data-url'),
                            method: 'POST',
                            data: form,
                            dataType: 'html',
                            success: (html) => {
                                cache.$notificationListContainer.hide();

                                cache.$notificationContainer.find('.message-date').html($(this).find('.message-date').html());
                                cache.$notificationContainer.find('.message-subject').html($(this).find('.message-subject').html());
                                cache.$notificationContainer.find('.message-body').html(html);

                                cache.$notificationContainer.show();

                                if ($(this).hasClass('js-unread')) {
                                    setMessageAsRead($(this));
                                }
                            },
                            error: function (err) {
                                new NotificationMessage(err.responseText, {
                                    duration: 3000,
                                    type: 'error'
                                }).show(cache.$toaster);
                            }
                        });
                    }
                    $('body').trigger('gtm:mp:messageFilterClick', { btnName: $(this).find('.message-subject').html() });
                });
            },
            error: (err) => {
                window.console.error(err);
            }
        });
    }
}

/**
 * show message center
 */
function showMessageCenter() {
    cache.$hamburgerMenuClose.trigger('click');
    $('body').addClass('notification-container-open');
    cache.$notificationHeader.addClass('header_message-center');
    cache.$notificationIcon.addClass('d-none');
    cache.$notificationIcon.attr('aria-hidden', true);
    cache.$notificationCloseIcon.removeClass('d-none');
    cache.$notificationCloseIcon.attr('aria-hidden', false);
    loadMessageList(true);
}

/**
 * hide message center
 */
function hideMessageCenter() {
    $('body').removeClass('notification-container-open');
    cache.$notificationHeader.removeClass('header_message-center');
    cache.$notificationIcon.removeClass('d-none');
    cache.$notificationIcon.attr('aria-hidden', false);
    cache.$notificationCloseIcon.addClass('d-none');
    cache.$notificationCloseIcon.attr('aria-hidden', true);
    cache.$notificationListContainer.fadeOut('fast', 'linear');
    cache.$notificationContainer.fadeOut('fast', 'linear');
}

module.exports = {
    initEvents: function () {
        cache.$headerSupportMpListItem.on('click', function () {
            hideMessageCenter();
        });

        cache.$notificationHeader.on('click', function () {
            let openMessageCenter = !cache.$notificationIcon.hasClass('d-none');

            if (openMessageCenter) {
                showMessageCenter();
            } else {
                hideMessageCenter();
            }
        });

        cache.$notificationContainerBack.on('click', function () {
            loadMessageList();
        });

        cache.$notificationHeader.on('click', function () {
            var btnName = 'messages';
            if ($('.js-notification-count').data('count') > 0) {
                btnName = 'new messages';
            }
            $('body').trigger('gtm:mp:headerMenuMessageCenterClick', { btnName: btnName });
        });

        cache.$notificationIcon.on('click', function () {
            $('body').trigger('gtm:mp:notificationClickEvent');
        });
    }
};
